export enum NGRXAuthType {
  saveToken = 'Save Token',
  saveUserProfile = 'Save User Profile',
  saveMSProfile = 'Save MS Profile',
  setReportsTo = 'Set Reports To',
  setAITokensLeft = 'Set AI Tokens Left',
  logOut = 'Log Out',
}

export enum GoogleSSOType {
  saveGoogleProfile = 'Save Google Profile',
}

export enum NGRXAuthSource {
  AuthModule = 'Auth Module',
  AuthMode = 'Auth Mode',
}

export enum NGRXAuthModeType {
  setMode = 'Set Mode',
  updateJWTToken = 'Update JWT Token',

  init = 'Init',
}
