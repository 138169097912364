import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { AppRoutes } from 'src/core/enums/app-routes.enum';
import { AuthMode, Permission } from 'src/core/enums/permissions.enum';
import { OrganizationData } from 'src/core/interfaces/organization.interface';
import { AuthService } from 'src/core/services/auth.service';
import { AppState } from 'src/core/store/app.state';
import { AuthModeActions } from 'src/core/store/auth/auth.actions';
import { authModeFeature } from 'src/core/store/auth/auth.selectors';
import {
  NavItemConfig,
  adminConfig,
  authSwitchConfig,
  config,
  managerConfig,
} from './side-nav-bar.config';
import { IconType } from 'src/core/enums/icon.enum';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.scss'],
})
export class SideNavBarComponent implements OnInit {
  public appRoutes = AppRoutes;
  public appPermission: typeof Permission = Permission;
  public navConfig = config;
  public organization: OrganizationData;
  public typeofAuthMode: typeof AuthMode = AuthMode;
  public isRolesPopupOpen: boolean = false;
  public authMode$ = new Observable<AuthMode>();
  public authSwitchConfig = authSwitchConfig;

  @Input() public open: boolean | null = true;

  constructor(private readonly store: Store<AppState>) {
    this.organization = AuthService.getOrganization();
  }

  public ngOnInit(): void {
    this.authMode$ = this.store.select(authModeFeature).pipe(
      tap((authMode) => {
        switch (authMode) {
          case AuthMode.Admin:
            this.navConfig = adminConfig;
            break;

          case AuthMode.Manager:
            this.navConfig = managerConfig;
            break;

          case AuthMode.Personal:
            this.navConfig = config;
            break;

          default:
            this.navConfig = config;
            break;
        }
      }),
    );
  }

  public navItem(item: NavItemConfig): string {
    const route = window.location.href;

    return route.includes(item.path)
      ? `icon-${item.icon}-filled`
      : `icon-${item.icon}`;
  }

  public setAuthMode(mode: AuthMode) {
    this.store.dispatch(AuthModeActions.updateJWTToken({ mode }));
  }

  public getRoleIcon(role: AuthMode) {
    if (role) {
      return this.authSwitchConfig.find((one) => one.mode === role)?.icon;
    }

    return IconType['user-circle'];
  }
}
