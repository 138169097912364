export enum NGRXGoalsType {
  initGoalsStore = 'Init Goals Store',
  loadGoalsFromDb = 'Load Goals From DB',

  loadGoals = 'Load Goals',
  addGoal = 'Add Goal',
  updateGoal = 'Update Goal',
  updateField = 'Update Field',
  setGoalState = 'Set Goal State',
  deleteEntity = 'Delete Entity',

  logOut = 'Log Out',

  /* Skills */
  addSkills = 'Add Skills',

  /* Resources */
  addResources = 'Add Resources',
  removeResource = 'Remove Resource',

  /* Contributors */
  removeContributor = 'Remove Contributor',
}

export enum NGRXActionItemType {
  createOne = 'Create one',
  updateOne = 'Update one',
  removeOne = 'Remove one',
}

export enum NGRXSubtaskType {
  add = 'Add',
  updateField = 'Update Field',
  delete = 'Delete ',
}

export enum NGRXGoalsSource {
  GoalsComponent = 'Goals Component',
  ActionItems = 'Action Items',
  Subtasks = 'Subtasks',
}
