enum Auth {
  base = 'auth',
  confirm = 'confirm',
  login = 'login',
  logout = 'logout',
  signup = 'signup',
  forgot = 'forgot-password',
  reset = 'reset-password',
  unlockAccount = 'unlock-account',
  recoveryEmailSent = 'recovery-email-sent',
  accountRecovered = 'account-recovered',
  awaitEmailConfirmation = 'await-email-confirmation',
  checkEmailToken = 'check-email-token',
  googleSSO = 'google-sso',
}

enum Calendar {
  base = 'calendar',
}

enum Dashboard {
  base = 'dashboard',
}

enum Feed {
  base = 'feed',
}

enum Feedback {
  base = 'feedback',
  give = 'give',
  createTemplate = 'create-template',
}

enum Goals {
  base = 'goals',
  goal = 'goal',
  goalId = 'goalId',
  objective = 'objective',
  objectiveId = 'objectiveId',
}

enum Meetings {
  base = 'meetings',
  allowGoalsReview = 'allow-goals-review',
}

enum OrganizationSettings {
  base = 'organization-settings',
}

enum PerformanceReview {
  base = 'performance-review',
  dashboard = 'dashboard',
}

enum Profile {
  base = 'profile',
  performanceReview = 'performance-review',
}

enum Team {
  base = 'team',
  invite = 'invite',
}

const invalidLink = 'invalid-link';
const AppRoutes = {
  home: Goals.base,
  invalidLink,
  Auth,
  Calendar,
  Dashboard,
  Feed,
  Feedback,
  Goals,
  Meetings,
  OrganizationSettings,
  PerformanceReview,
  Profile,
  Team,
};

export { AppRoutes };

export const RouteData: { [field: string]: string } = {
  [Dashboard.base]: 'Metrics',
  [Goals.base]: 'Goals',
  [Feed.base]: 'Feed',
  [Feedback.base]: 'Review',
  [Feedback.give]: 'Feedback',
  [Meetings.base]: 'Meetings',
  [PerformanceReview.base]: 'Performance Review',
  [Profile.base]: 'Profile',
  [Team.base]: 'Team',
};
